import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import ErrorAlert from "~/components/ErrorAlert"
import { useAuth } from "~/hooks/useAuth"
import { usePrinticularApi } from "~/hooks/usePrinticularApi"
import AccountLayout from "~/templates/layout/account/AccountLayout"

type FormData = {
  name: string
  emailAddress: string
  phoneNumber: string
}

const Account = () => {
  const api = usePrinticularApi()
  const {
    setUserInfos,
    state: { id, name, emailAddress, phoneNumber, isLoading },
  } = useAuth()
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name,
      emailAddress,
      phoneNumber,
    },
  })

  const [error, setError] = useState<any>()
  const [isSaving, setIsSaving] = useState(false)
  const toast = useToast()

  useEffect(() => {
    reset({ name, emailAddress, phoneNumber })
  }, [emailAddress, name, phoneNumber, reset])

  const onSubmit = async (data: FormData) => {
    setIsSaving(true)

    try {
      const { name, phoneNumber } = data
      await api.updateUserAccount({
        id,
        name,
        phoneNumber,
      })

      setUserInfos({ name, phoneNumber })

      toast({
        title: t("pages.account.index.ToastTitle"),
        description: t("pages.account.index.ToastDescription"),
        status: "success",
        duration: 2000,
        isClosable: true,
      })
    } catch (error) {
      setError(error)
    }

    setIsSaving(false)
  }

  return (
    <AccountLayout
      title={t("pages.account.index.Title")}
      description={t("pages.account.index.Description")}
      isLoading={isLoading}
      fullWidth={false}
    >
      <Box mt={5}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: "100%", margin: 0 }}
        >
          <Stack spacing={5}>
            {error && <ErrorAlert data={error} displayErrorsList={false} />}

            <FormControl
              isInvalid={!!errors.name}
              isDisabled={isLoading || isSaving}
              isRequired={true}
            >
              <FormLabel>{t("pages.account.index.Name")}</FormLabel>
              <Input
                id="account-name"
                type="text"
                placeholder={t("pages.account.index.Name")}
                variant="filled"
                {...register("name", {
                  required: t<string>("pages.account.index.Required"),
                })}
              />
              {!!errors.name && (
                <FormErrorMessage>{errors.name.message}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              isInvalid={!!errors.phoneNumber}
              isDisabled={isLoading || isSaving}
              isRequired={true}
            >
              <FormLabel>{t("pages.account.index.PhoneNumber")}</FormLabel>
              <Input
                id="account-phone-number"
                type="text"
                placeholder={t("pages.account.index.PhoneNumber")}
                variant="filled"
                {...register("phoneNumber", {
                  required: t<string>("pages.account.index.Required"),
                })}
              />
              {!!errors.phoneNumber && (
                <FormErrorMessage>
                  {errors.phoneNumber.message}
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl isDisabled={true}>
              <FormLabel>{t("pages.account.index.Email")}</FormLabel>
              <Input
                type="email"
                placeholder={t("pages.account.index.Email")}
                variant="filled"
                {...register("emailAddress")}
              />
            </FormControl>

            <Flex justifyContent="flex-end">
              <Button
                id="account-save"
                colorScheme="primary"
                color="primary.text"
                type="submit"
                isLoading={isSaving}
                isDisabled={isLoading}
              >
                {t("pages.account.index.Save")}
              </Button>
            </Flex>
          </Stack>
        </form>
      </Box>
    </AccountLayout>
  )
}

export default Account
